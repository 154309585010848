<template>
  <div class="main-box" v-cloak>
    <div class="logo" v-cloak>
      <img
        src="../assets/img/banner_about us.png"
        alt=""
        style="width: 100%; height: auto"
      />
    </div>
    <div class="container position">
      <tabbar />
    </div>
    <tail />
  </div>
</template>

<script>
import Tabbar from "../components/common/tabbar.vue";
import Tail from "../components/common/tail.vue";

export default {
  components: {
    Tabbar,
    Tail,
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  position: relative;
  width: 100%;
  height: 100%;
  .logo {
    width: 100%;
    overflow: hidden;
  }
}
.position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
[v-cloak] {
  display: none;
}
</style>